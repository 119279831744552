import { Injectable, TemplateRef } from '@angular/core';

export interface Toast {
	textOrHtml?: string;
  classname?: string;
  delay?: number;
}

@Injectable({ providedIn: 'root' })
export class ToastService {
	toasts: Toast[] = [];
	private shownMessages = new Set<string>(); // Track shown messages

  show(toast: Toast) {
    if (toast.textOrHtml && !this.shownMessages.has(toast.textOrHtml)) {
      this.toasts.push(toast);
      this.shownMessages.add(toast.textOrHtml);

      // Optionally, remove the message from the set after the delay
      if (toast.delay) {
        setTimeout(() => {
          this.shownMessages.delete(toast.textOrHtml!);
        }, toast.delay);
      }
    }
  }

	remove(toast: Toast) {
		this.toasts = this.toasts.filter((t) => t !== toast);
	}

	clear() {
		this.toasts.splice(0, this.toasts.length);
		this.shownMessages.clear();
	}
}